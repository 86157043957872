import { assertUnreachable } from '@sgme/fp';
import type { FxCashState } from 'state/fxCashs/fxCashsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';

export function getMarkupCurrency({
  markupCurrency,
  values: { currency1, currency2 },
}: Pick<FxCashState, 'markupCurrency' | 'values'>) {
  return markupCurrency === 1 ? currency1 : currency2;
}

export interface MarkupComputationValues {
  amountCurrency: CurrencyChoice;
  markupCurrency: CurrencyChoice;
  notional: number;
  // ESP = SpotCash.bidMargin converted in rate (not in points)
  bidTotalMargin: number;
  // ESP = SpotCash.askMargin converted in rate (not in points)
  askTotalMargin: number;
  // ESP = IESPTraderPriceUnderThreshold.traderBid
  // RFS = spotWithMargin.bid + forwardBidPoints in rate (not in points)
  //  spotWithMargin.bid = isInternalUser ? spotWithoutMargin.bid + cash.bidMargin  : spotWithMargin.bid
  //  forwardBidPoints = stream.quote.forwardPointsWithMargin.bid
  allInBid: number;
  // ESP = IESPTraderPriceUnderThreshold.traderAsk
  allInAsk: number;
}

interface MarkupResult {
  bidMarkup: number;
  askMarkup: number;
}

/**
 * Parameters sent to the `computeMarkup` function for RFS and ESP:
 *
 * - `allInBid`: The bid price including all margins.
 *   - For RFS: Calculated as `spot bid price + forward bid points`.
 *   - For ESP: Directly taken as the trader bid price.
 *
 * - `allInAsk`: The ask price including all margins.
 *   - For RFS: Calculated as `spot ask price + forward ask points`.
 *   - For ESP: Directly taken as the trader ask price.
 *
 * - `bidTotalMargin`: The total margin applied to the bid price.
 *   - For RFS: Calculated as `spot bid margin + forward bid points`.
 *   - For ESP: The spot bid margin converted to a rate.
 *
 * - `askTotalMargin`: The total margin applied to the ask price.
 *   - For RFS: Calculated as `spot ask margin + forward ask points`.
 *   - For ESP: The spot ask margin converted to a rate.
 */
export function computeMarkup(values: MarkupComputationValues): MarkupResult {
  switch (values.amountCurrency) {
    case 1:
      return computeMarkupWithNotionalInCcy1(values);
    case 2:
      return computeMarkupWithNotionalInCcy2(values);
    default:
      assertUnreachable(values.amountCurrency, 'Amount currency is not handled');
  }
}

/**
 * Computes the markup when the notional is in the first currency (Ccy1).
 *
 * @param {MarkupComputationValues} values - The values needed for the computation.
 * @returns {MarkupResult} The computed bid and ask markups.
 *
 * This function calculates the markup for a FX cash when the notional amount is in the first
 * currency (Ccy1). The result is an object containing the bid and ask markups.
 *
 * Steps and formulas:
 * 1. Calculate the bid and ask markups in the second currency (Ccy2):
 *    - `bidMarkupCcy2 = notional * bidTotalMargin`
 *    - `askMarkupCcy2 = notional * askTotalMargin`
 * 2. Depending on the markup currency, compute the final bid and ask markups:
 *    - If the markup currency is Ccy1:
 *      - `bidMarkup = Math.round(bidMarkupCcy2 / allInAsk)`
 *      - `askMarkup = Math.round(askMarkupCcy2 / allInBid)`
 *    - If the markup currency is Ccy2:
 *      - `bidMarkup = Math.round(bidMarkupCcy2)`
 *      - `askMarkup = Math.round(askMarkupCcy2)`
 * 3. If the markup currency is not handled, assert unreachable.
 *
 * Functional definitions:
 * - `bidTotalMargin`: The total margin applied to the bid price. For RFS, it is the sum of
 *   the spot bid margin and the forward bid points. For ESP, it is the spot bid margin
 *   converted to a rate.
 * - `askTotalMargin`: The total margin applied to the ask price. For RFS, it is the sum of
 *   the spot ask margin and the forward ask points. For ESP, it is the spot ask margin
 *   converted to a rate.
 * - `allInBid`: The bid price including all margins. For RFS, it is the spot bid price plus
 *   the forward bid points. For ESP, it is the trader bid price.
 * - `allInAsk`: The ask price including all margins. For RFS, it is the spot ask price plus
 *   the forward ask points. For ESP, it is the trader ask price.
 */
function computeMarkupWithNotionalInCcy1({
  markupCurrency,
  notional,
  bidTotalMargin,
  askTotalMargin,
  allInBid,
  allInAsk,
}: MarkupComputationValues): MarkupResult {
  const bidMarkupCcy2 = notional * bidTotalMargin;
  const askMarkupCcy2 = notional * askTotalMargin;
  switch (markupCurrency) {
    case 1:
      return {
        bidMarkup: Math.round(bidMarkupCcy2 / allInAsk),
        askMarkup: Math.round(askMarkupCcy2 / allInBid),
      };
    case 2:
      return {
        bidMarkup: Math.round(bidMarkupCcy2),
        askMarkup: Math.round(askMarkupCcy2),
      };
    default:
      assertUnreachable(markupCurrency, 'Markup currency is not handled');
  }
}

/**
 * Computes the markup when the notional is in the second currency (Ccy2).
 *
 * @param {MarkupComputationValues} values - The values needed for the computation.
 * @returns {MarkupResult} The computed bid and ask markups.
 *
 * This function calculates the markup for a FX cash when the notional amount is in the second
 * currency (Ccy2). The result is an object containing the bid and ask markups.
 *
 * Steps and formulas:
 * 1. Calculate the trader bid and ask prices:
 *    - `traderBid = allInBid - bidTotalMargin`
 *    - `traderAsk = allInAsk + askTotalMargin`
 * 2. Calculate the conversion factors:
 *    - `conversionFactorBid = 1 / traderBid - 1 / allInBid`
 *    - `conversionFactorAsk = 1 / allInAsk - 1 / traderAsk`
 * 3. Calculate the bid and ask markups in the first currency (Ccy1):
 *    - `bidMarkupCcy1 = notional * conversionFactorBid`
 *    - `askMarkupCcy1 = notional * conversionFactorAsk`
 * 4. Depending on the markup currency, compute the final bid and ask markups:
 *    - If the markup currency is Ccy1:
 *      - `bidMarkup = Math.round(bidMarkupCcy1)`
 *      - `askMarkup = Math.round(askMarkupCcy1)`
 *    - If the markup currency is Ccy2:
 *      - `bidMarkup = Math.round(bidMarkupCcy1 * allInAsk)`
 *      - `askMarkup = Math.round(askMarkupCcy1 * allInBid)`
 * 5. If the markup currency is not handled, assert unreachable.
 *
 * Functional definitions:
 * - `bidTotalMargin`: The total margin applied to the bid price. For RFS, it is the sum of
 *   the spot bid margin and the forward bid points. For ESP, it is the spot bid margin
 *   converted to a rate.
 * - `askTotalMargin`: The total margin applied to the ask price. For RFS, it is the sum of
 *   the spot ask margin and the forward ask points. For ESP, it is the spot ask margin
 *   converted to a rate.
 * - `allInBid`: The bid price including all margins. For RFS, it is the spot bid price plus
 *   the forward bid points. For ESP, it is the trader bid price.
 * - `allInAsk`: The ask price including all margins. For RFS, it is the spot ask price plus
 *   the forward ask points. For ESP, it is the trader ask price.
 */
function computeMarkupWithNotionalInCcy2({
  markupCurrency,
  notional,
  bidTotalMargin,
  askTotalMargin,
  allInBid,
  allInAsk,
}: MarkupComputationValues): MarkupResult {
  const traderBid = allInBid - bidTotalMargin;
  const traderAsk = allInAsk + askTotalMargin;

  const conversionFactorBid = 1 / traderBid - 1 / allInBid;
  const conversionFactorAsk = 1 / allInAsk - 1 / traderAsk;

  const bidMarkupCcy1 = notional * conversionFactorBid;
  const askMarkupCcy1 = notional * conversionFactorAsk;

  switch (markupCurrency) {
    case 1:
      return {
        bidMarkup: Math.round(bidMarkupCcy1),
        askMarkup: Math.round(askMarkupCcy1),
      };
    case 2:
      return {
        bidMarkup: Math.round(bidMarkupCcy1 * allInAsk),
        askMarkup: Math.round(askMarkupCcy1 * allInBid),
      };
    default:
      assertUnreachable(markupCurrency, 'Markup currency is not handled');
  }
}
