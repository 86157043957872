import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state';
import { actionCreators } from '../../../state/actions';
import { selectors } from '../../../state/selectors';
import { useState } from 'react';
import { Button, Modal } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';

export type CloseTabButtonProps = {
  tabId: string;
};

export const CloseTabButton = (props: CloseTabButtonProps) => {
  const { tabId } = props;
  const [showCloseModal, setShowCloseModal] = useState(false);
  const dispatch = useDispatch();

  const isShared = useAppSelector((state) => selectors.isTabShared(state, tabId));

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (isShared) {
      setShowCloseModal(true);
    } else {
      dispatch(actionCreators.clientWorkspaceTabClosedThunk(tabId));
    }
  };
  const onDelete = () => {
    dispatch(actionCreators.stopTabSharingThunk(tabId));
    dispatch(actionCreators.clientWorkspaceTabClosedThunk(tabId));
    dispatch(actionCreators.saveWorkspaceThunk());
  };

  const onClose = () => {
    setShowCloseModal(false);
  };

  return (
    <>
      <button type="button" className="align-self-baseline btn btn-flat-secondary btn-icon p-0 ms-1" onClick={onClick}>
        <i className="icon icon-sm px-2">close</i>
      </button>
      <Modal show={showCloseModal} onClose={onClose}>
        <Modal.Header />
        <Modal.Body className="fw-bold ">
          <FormattedMessage id="tab.close.modal.comfirmation" />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary btn-md" type="button" onClick={onClose}>
            <FormattedMessage id="tab.close.modal.cancel" />
          </Button>
          <Button className="btn btn-primary btn-md" type="button" onClick={onDelete}>
            <FormattedMessage id="tab.close.modal.delete" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
